.popup {
  .modal-content {
    background-color: white;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .close {
    width:100%;
    text-align: right;
    font-weight: bold;
    cursor: pointer;
  }
  .mask {
    background-color: rgba(0,0,0,.1);
    position: fixed;
    top: 0;
    left:0;
    width: 100%;
    height: 100%;
    text-align: center;
    backdrop-filter: blur(1px);
  }
}
