@import '../../util/sassvars';

.daily-dos {
  .time {
    font-size: 10px;
    color: $ghost-color;
    text-align: right;
    white-space: nowrap;
  }
  .section {
    margin-top: 15px;
    text-decoration: underline;
    font-weight: bold;
    color: $heading-color;
  }
  .desc {
    vertical-align: top;
  }
  .spacer {
    height: 21px;
    border-top: 1px solid black;
  }
  .dragging {
    position: fixed;
  }
}
