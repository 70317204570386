@import '../../util/sassvars';

.daily-planning {
  .top {
    vertical-align: top;
  }
  .section {
    margin-top: 10px;
    font-size: 14px;
    font-weight: bold;
    color: $label-color;
    font-variant: small-caps;
  }
  .taskbtn.checked {
    outline: 2px solid green;
  }
  .section-heading {
    margin-top: 2px;
    border-top: 1px solid $heading-color;
  }
  .section-heading {
    font-size: 14px;
    font-variant: small-caps;
    color: $heading-color;
  }

  .tasks {
    .section {
      margin-top: auto;
    }
    border: $box-border;
    border-radius: $box-radius;
    padding: 5px 5px 15px 5px;
    box-shadow: $box-radius;
  }

  .add-checklist {
    padding: 2px 8px;
    font-size: 13px;
    color: #888;
    border: 1px solid #888;
    border-radius: 5px;
    margin: 2px;
    width: 200px;
  }
}
.daily-notes {
  .head {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .note-buttons {
    padding-bottom: 5px;
  }
  @media screen and (max-width:520px) {
    .note-buttons {
      width: 90px;
      .button {
        width: 40px;
        font-size: 11px;
      }
    }
  }
}
.process-notes {
  margin-bottom: 10px;
  .msg {
    font-size: $subtext-size;
    color: $subtext-color;
  }
  .note {
    cursor: pointer;
  }
}
.choose-tasks {
  .check-label {
    vertical-align: top;
  }
  .show-more {
    font-size: 15px;
    color: #446699;
    padding-bottom: 10px;
  }
  .add-to-do-buttons {
    .button {
      margin-top: 10px;
    }
  }
}
.calendar-tasks {
  .task-line {
    &.disabled {
      color: $subtext-color;
    }
    .button-label {
      vertical-align: top;
    }
  }
  .calendar-item {
    .cell {
      vertical-align: top;
      height: 30px;
    }
  }
  .bell {
    padding-left: 4px;
    vertical-align: top;
  }
}
