$red-9: #e6d3d3;
$green-9: #d3e6d3;
$blue-9: #d3d3e6;
$greyish-9: #d3d3e0;
$red-8: #ccbcbc;
$green-8: #bcccbc;
$blue-8: #bcbccc;   // HSV 240, 8%,
$grey-8: #bcbcbc;
$greyish-8: #bcbcc4;
$grey-7: #a3a3a3;
$green-7: #a3b2a3;
$red-5: #807474;
$green-5: #748074;
$blue-5: #747480;
$grey-5: #747474;

$base-background: #f6f6f6;
$base-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue";
$base-font-color: #162233;
$base-font-size: 14px;
$base-hover-color: #375580;

$heading-color: #446699;
$box-border: 1px solid $grey-8;
$box-shadow: 3px 3px 5px $grey-8;
$box-radius: 2px;

$accent-background: #ffffff;
$accent-color: #333333;
$label-color: #446699;
$ghost-label-color: #99aacc;
$label-size: 13px;
$ghost-color: $grey-8;  // ghost text
$disabled-color: $grey-7;

$error-color: #dd3333;

$link-color: #003366;

$primary-action-background: #446699;
$primary-action-background-hover: #5577aa;
$primary-action-color: $base-background;
$minor-action-background: $green-7;
$minor-action-background-hover: $green-8;

$mono-font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;

$subtext-color: $green-5;
$subtext-size: 11px;

$tiny-break: 400px;
