@import '../../util/sassvars';

.edit-objective {
  #frequency,
  #duration {
    margin-right: 10px;
  }
  #frequencyUnit {
    margin-left: 10px;
  }
  #goal {
    margin-left: 10px;
  }
}

.table {
  .link {
    font-size: 13px;
  }
  border-collapse: collapse;
  .row {
    display: table-row;
    &.value {
      border-bottom: 1px solid #446699;
      .link {
        color: $heading-color;
        font-weight: bold;
        font-size: 14px;
      }
      .add-link {
        .link {
          font-weight: normal;
          font-size: 12px;
        }
      }
    }
    &.spacer {
      .cell {
        height: 15px;
      }
    }
    &.inactive {
      color: $ghost-color;
      .link {
        color: $ghost-color;
      }
    }
  }
  .cell {
    display: table-cell;
    padding: 1px 5px;
    .link {
      cursor: pointer;
    }
  }
}

.add-link {
  float: right;
  padding-right: 10px;
  position: relative;
  top: 5px;
  .link {
    color: #5577aa;
    font-size: 12px;
  }
}

.heading {
  font-weight: bold;
}

.objective-row {
  cursor: pointer;
}

.wheelTree-2 .name {
  padding-left: 12px;
}
.wheelTree-3 .name {
  padding-left: 24px;
}
