@import '../../util/sassvars';

.drop-down {
  label {
    display: block;
    font-size: $label-size;
    color: $label-color;
    text-align: left;
  }
  &:invalid {
    select {
      color: $ghost-color;
    }
  }
  &.error {
    label {
      color: $error-color;
    }
    select {
      border: 1px solid $error-color;
      &:focus {
        border: 2px solid $error-color;
        outline-width: 0;
      }
    }
  }
}
