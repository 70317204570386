@import '/util/sassvars';

body {
  margin: 0;
  background-color: $base-background;
  font-family: $base-font-family;
  font-size: $base-font-size;
  color: $base-font-color;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .no-click {
    pointer-events: none;
  }
}

.right {
  text-align: right;
}
.row {
  display: table-row;
}
.cell {
  display: table-cell;
    padding-right: 10px;
}
