@import '../../util/sassvars';

.common-task-object {
  .lone-label {
    display: block;
    font-size: $label-size;
    color: $label-color;
    text-align: left;
  }
}
