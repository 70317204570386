@import '../../util/sassvars';

.button {
  background-color: $primary-action-background;
  position: relative;
  color: $primary-action-color;
  width: 160px;
  height: 30px;
  font-size: 13px;
  font-weight: bold;
  margin-right: 5px;
  cursor: pointer;
  display: inline-block;

  .vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    text-align: center;
    width: 100%;
    padding-bottom: 4px; // half of font size
  }

  // Styles
  &.finger {
    width: 60px;
    height: 60px;
    font-weight: normal;
    font-size: 12px;
  }
  &.small-finger {
    width: 24px;
    height: 24px;
    font-weight: normal;
    font-size: 12px;
  }
  &.medium {
    width: 120px;
  }
  &.smedium {
    width: 80px;
  }
  &.small {
    width: 60px;
  }
  &.very-small {
    border-radius: 2px;
    font-size: 12px;
    height: 24px;
    width: 60px;
    font-weight: bold;
    font-variant: small-caps;
  }
  &.smallest {
    width: 60px;
    font-size: 12px;
    height: 24px;
    @media screen and (max-width: $tiny-break) {
      width: 40px;
      font-size: 11px;
    }
  }
  &.tiny {
    border-radius: 2px;
    font-size: 12px;
    height: 16px;
    width: 16px;
    font-weight: normal;
    font-variant: small-caps;
  }
  &.txt {
    border: none;
    width: auto;
    height: auto;
    background-color: inherit;
    color: $base-font-color;
    position: static;
    padding: 0px 5px;
    .vertical-center {
      top: auto;
      transform: none;
      position: static
    }
  }

  // Colors
  &.white {
    background-color: $base-background;
    border: 1px solid $blue-8;
    color: $base-font-color;
  }
  &.blue {
    background-color: $blue-9;
    border: 1px solid $blue-8;
    color: $base-font-color;
  }
  &.grey {
    background-color: $greyish-8;
    border: 1px solid $blue-8;
    color: $base-font-color;
  }
  &.minor {
    background-color: $minor-action-background;
    border: 1px solid $minor-action-background;
  }
}

.button:hover {
  background-color: $primary-action-background-hover;
  &.blue {
    background-color: $blue-8;
  }
  &.grey {
    background-color: $greyish-9;
  }
  &.minor {
    background-color: $minor-action-background-hover;
  }
}
