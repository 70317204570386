@import '../../util/sassvars';

.login {
  .strike {
    width: 43%;
    border-bottom: 1px solid #cccccc;
    display: inline-block;
    vertical-align: middle;
  }
  .partner-auth {
    .google-icon {
      background: url("/google.svg");
      width: 20px;
      height: 20px;
      display: inline-block;
      background-size:20px 20px;
      vertical-align: middle;
      margin-right: 8px;
    }
    .button {
      background-color: white;
      border-radius: 22px;
      border: 2px solid $grey-5;
      color: $base-font-color;
      font-weight: normal;
      height: 32px;
      .vertical-center {
        padding: 0;
      }
    }
  }
}

.login-widget {
  .note-text {
    font-size:11px;
  }
  .signup-link {
    margin-top: 20px;
  }
  border: 1px solid #DDDDDD;
  padding: 5px;
  display: inline-block;
}
