.obj-dropdown {
  .link {
    margin-left: 10px;
  }
  select {
    option.lvl-1 {
      padding-left: 10px;
    }
    option.lvl-2 {
      padding-left: 20px;
    }
    option.lvl-3 {
      padding-left: 30px;
    }
  }
}
