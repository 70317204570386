@import '../../util/sassvars';

.project, .projects {
.section {
    margin-top: 10px;
    font-size: 14px;
    font-weight: bold;
    color: $label-color;
    font-variant: small-caps;
  }
}

.project {
  .cell.task {
    cursor: pointer;
  }
  .cell.level-1 {
    .bullet {
      color: $ghost-label-color;
      font-size: 11px;
      vertical-align: top;
      line-height: 21px;
      padding-right: 5px;

    }
  }
  .cell.level-2 {
    padding-left: 20px;
  }
  .cell.level-3 {
    padding-left: 30px;
  }
  .cell.task-label {
    font-size: 12px;
    cursor: pointer;
  }
  .cell.inactive {
    color: $ghost-color;
    .bullet {
      color: $ghost-color;
    }
  }
  .cell.complete {
    color: $ghost-color;
    text-decoration: line-through
  }
  .project-info {
    .can-edit {
      cursor: pointer;
    }
  }
}

.projects {
    .project-link {
      cursor: pointer;
    }
}
