@import '../../util/sassvars';

span.input {
  display: inline-block;
  label {
    display: block;
    font-size: $label-size;
    color: $label-color;
    text-align: left;
    width: 1px;
  }
  &.error {
    label {
      color: $error-color;
    }
    textarea, input {
      border: 1px solid $error-color;
      &:focus {
        border: 2px solid $error-color;
        outline-width: 0;
      }
    }
  }
  &.sz100 {
    input {
      width: 100%;
    }
  }
  &.sm1 {
    input {
      width: 20px;
    }
  }
  &.sm2 {
    input {
      width: 40px;
    }
  }
  &.sm3 {
    input {
      width: 60px;
    }
  }
  &.me1 {
    input {
      width: 100px;
    }
  }
  &.me2 {
    input {
      width: 150px;
    }
  }
  &.me3 {
    input {
      width: 200px;
    }
  }
  &.lg1 {
    input {
      width: 300px;
    }
  }
  &.lg2 {
    input {
      width: 400px;
    }
  }
  &.lg3 {
    input {
      width: 500px;
    }
  }
}
