@import '../../util/sassvars';

.values {
  .heading {
    font-weight: bold
  }
  .objective-row {
    cursor: pointer;
  }
  .value {
    border-bottom: 1px solid #446699;
    color: $heading-color;
    font-weight: bold;
    font-size: 14px;
    margin-top: 14px;
    cursor: pointer;
  }
}
