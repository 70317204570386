@import '../../util/sassvars';

$header-height: 26px;
$header-margin: 14px;

.App {
  text-align: left;
  font-size: 12pt;

  .App-logo {
    height: 40vmin;
  }

  .App-header {
    background-color: $accent-background;
    color: $accent-color;
    box-shadow: 0px 5px 5px rgba(0,0,0,.1);
    position: fixed;
    width: 100%;
    height: $header-height;

    padding: 3px 6px;
    width: calc(100% - 12px);

    .right {
      float: right;

      .option {
        font-size: .9rem;
        top: 50%;
        right: 5px;
        position: absolute;
        transform: translateY(-50%);
      }
    }
  }
  .header-spacer {
    height: $header-height;
    margin-bottom: $header-margin;
  }

  .App-content {
    max-height: calc(100vh - 30px);
    overflow-y: scroll;
    margin-left: 10px;
  }

  .link, a {
    color: $link-color;
    font-size: 14px;
    cursor: pointer;
  }
  .form-elem {
    margin-top: 10px;
  }
}
