$work-color:  #ccddcc;
$work-border-color: #abbaab;
$work-font-color: #606960;
$other-color:  #ddddcc;
$other-border-color: #babaab;
$other-font-color: #696960;
$sleep-color: #ccccdd;
$sleep-border-color: #ababba;
$sleep-font-color: #606069;
$shadow-color: rgba(100, 100, 100, .25);

.settings {

}
.week-blocker {
  .align-button {
    position: relative;
    top: 6px;
  }
  .sleep {
    background-color: $sleep-color;
    border: 1px solid $sleep-border-color;
    color: $sleep-font-color;
  }
  .work {
    background-color: $work-color;
    border: 1px solid $work-border-color;
    color: $work-font-color;
  }
  .other {
    background-color: $other-color;
    border: 1px solid $other-border-color;
    color: $other-font-color;
  }
  .source {
    padding: 5px;
    margin-bottom: 5px;
    .add-block {
      width: 50px;
      height: 50px;
      font-size: 12px;
      margin-right: 15px;
      box-shadow: 5px 5px 5px #bbbbbb;
      display: inline-block;
    }
  }
  .weekly-calendar {
    .canvas-container {
      border: 1px solid black;
      .wb-canvas {
        width: 100%;
        height: 400px;
      }
    }
    .items {
      position: relative;
      .wb-item {
        position: absolute;
        text-align: center;
        font-size: 13px;
        box-shadow: 3px 3px 5px $shadow-color;
        &.dragged {
          pointer-events: none;
          &.off {
            box-shadow: none;
            border: 1px solid #aa6666;
          }
        }
      }
      z-index: 2000;
    }
  }
}
