@import '../../util/sassvars';

span.checkbox {
  display: inline;
  cursor: pointer;
  input {
    cursor: pointer;
  }
  label {
    cursor: pointer;
    display: inline;
    color: $base-font-color;
    text-align: left;
    .imgtext {
      padding-left: 5px;
      vertical-align: top;
    }
  }
  &.error {
    label {
      color: $error-color;
    }
    textarea, input {
      border: 1px solid $error-color;
      &:focus {
        border: 2px solid $error-color;
        outline-width: 0;
      }
    }
  }
  &.disabled {
    cursor: default;
    input {
      cursor: default;
    }
    label {
      color: $disabled-color;
      cursor: default;
    }
  }
}
