.people {
  .heading {
    margin-bottom: 10px;
  }
  .person-line {
    cursor: pointer;
  }
  .buttons {
    margin-top: 10px;
  }
}
