@import '../../util/sassvars';

.tasks {
  .task {
    cursor: pointer;
    &.disabled {
      color: $subtext-color;
      text-decoration: line-through;
    }
  }
}
