@import '../../util/sassvars';

.project-board {
  .pb-column {
    width: 200px;
    .empty {
      height: 100px;
    }
  }
  .taskGroup {
    border-radius: $box-radius;
    margin: 0px 10px 10px 0px;
    .divider {
      padding-top: 3px;
    }
    .tg-header {
      border-left: $box-border;
      border-top: $box-border;
      border-right: $box-border;
      font-size: .8rem;
      padding: 0px 5px;
    }
    .task {
      border: $box-border;
      background-color: white;
      box-shadow: $box-shadow;
      .task-body {
        padding: 1px 5px;
      }
      &.lite {
        background-color: #F0FFF0;
      }
    }
    cursor: grab;
  }
  .spacer {
    height: 20px;
    background-color: #FFC0C0;
  }
}
