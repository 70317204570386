@import '../../util/sassvars';

$calBk: #F0F0FF;

.calendar-day {
  .calendar-day-title {
    .date {
      display: inline-block;
    }
    .pick-style {
      float: right;
      display: inline-block;
    }
  }
  .time {
    text-align: right;
    &.empty {
      font-size: 11px;
    }
  }
  .cal-body {
    background-color: #EEEEEE;
    .begin {
      padding-right: 2px;
    }
    .end {
      font-size: 11px;
    }
  }
  .cell.start {
    border-top: 1px solid #888899;
  }
  .cell.start, .cell.cont {
    border-left: 1px solid #888888;
    border-right: 1px solid #888888;
    background-color: $calBk;
  }
}
.calendar-day-list {
  border: 1px solid #DDDDDD;
  box-shadow: 5px 5px 5px #DDDDDD;
  .headings {
    font-size: 11px;
    font-weight: 700;
    .desc {
      min-width: 200px;
    }
  }
  .row.hilight {
    .cell {
      border-top: 1px solid #44ff44;
    }
  }
  .bottom-spacer {
    margin-bottom: 15px;
    &.hilight {
      border-top: 1px solid #44ff44;
    }
  }
  .row.event {
    cursor: pointer;
    &:hover {
      color: $base-hover-color;
    }
  }
}
