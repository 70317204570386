@import '../../util/sassvars';

.weekly-planning {
  .suggested {
    margin-top: 10px;
  }
  .button.tiny {
    position: relative;
    top: 4px;
  }
  .section {
    margin-top: 15px;
    text-decoration: underline;
    font-weight: bold;
    color: $heading-color;
  }
  .label {
    display: block;
    font-size: $label-size;
    color: $label-color;
    text-align: left;
  }
  .tasks {
    .title, .section {
      margin-top: auto;
    }
    border: 1px solid $grey-8;
    border-radius: 2px;
    padding: 0px 5px 15px 5px;
    box-shadow: 3px 3px 5px $grey-8;
  }
  .task-desc {
    vertical-align: top;
    padding-top: 2px;
  }
  .btn-extra {
    margin-left: 4px;
  }
  .pad-5 {
    margin-right: 5px;
  }
  .pad-10 {
    margin-right: 10px;
  }
  .spacer {
    height: 7px;
  }
  .add-more-info {
    .button {
      margin-top: 10px;
    }
  }
  .popup {
    .modal-content {
      padding: 10px;
      .footer {
        margin-top: 15px;
      }
    }
  }
}
.title {
  margin-top: 10px;
  font-variant-caps: small-caps;
  font-weight: bold;
  color: $heading-color;

  &.small {
    font-variant-caps: normal;
    font-size: 12px;
    margin-top: 10px;
  }
}