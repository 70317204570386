.contained {
  &.dragging {
    position: fixed;
    opacity: 0.8;
    cursor: grabbing;
    z-index: 1000;
  }
  &.static {
    position: relative;
    top: 0;
    left: 0;
    cursor: grab;
  }
}
.droppable {
  display: inline-block;
  vertical-align: top;
}
.shadow {
  border: 1px solid red;
  margin: 0px -2px -2px 0px;
}
