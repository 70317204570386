.input-time-container {
  &.focus {
    border: 2px solid red;
  }
  position: relative;
  .time-input {
    display: inline-block;
    border: 1px solid black;
    width: 40px;
    text-align: right;
    padding: 0px 2px;
  }
}
.input-clock {
  position: absolute;
  border: 1px solid black;
  background-color: white;
  transform: scale(1.4) translate(10px, 14px);
  z-index: 1000;
  .smallest {
    width: 40px;
  }
}
